import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import Cookies from 'js-cookie'
import jquery from "jquery";
import axios from "axios";
import qs from "qs";
import "element-ui/lib/theme-chalk/index.css";
import "swiper/dist/css/swiper.min.css";
import "swiper/dist/js/swiper.min";
import './assets/css/app_css.css';
import "./assets/css/quill.core.css";
import "./assets/css/quill.snow.css";
import "./assets/css/quill.bubble.css";


axios.interceptors.request.use(function (config) {
  config.url = encodeURI(config.url)
  return config;
}, function (error) {
  return Promise.reject(error);
});


Vue.use(ElementUI);
Vue.prototype.http = process.env.NODE_ENV === "production" ? "https://cos.hyfutures.com/" : "https://bluecat-1256948939.cos.ap-beijing.myqcloud.com/"; //全局注册
Vue.prototype.vod = process.env.NODE_ENV === "production" ? "https://1344507933.vod-qcloud.com/" : "https://1256948939.vod2.myqcloud.com/"; //全局注册
Vue.prototype.$axios = axios; //全局注册，使用方法为:this.$axios
Vue.prototype.qs = qs; //全局注册，使用方法为:this.qs
Vue.prototype.$ = jquery;
Vue.prototype.cookies = Cookies;

router.afterEach(() => {
  if (!Cookies.get('token')) {
    store.dispatch("setUser", '');
  }
});

Vue.config.productionTip = false;
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
